import React from 'react';
import CartIconImage from '../../../assets/images/cart-icon.svg';
import {
  CategoriesHeader,
  BasketCount,
  IconButton,
  SecondaryButton,
} from '@fingermarkglobal/cringer.components';
import ArrowRight from 'heroicons/solid/arrow-right.svg';

const Header = ({
  title = null,
  total = null,
  previousText = 'Back',
  previousAction = null,
  allowPreviousAction = true,
  className = '',
  count = 0,
  showBasketCount = true,
  showCategoriesHeader = false,
  onBasketClick = () => {},
  showMenuAction = true,
  menuText = 'Menu',
  menuAction = () => {},
  basketText = null,
  currentRoute = '',
  showSubtotal = true,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
} = {}) => {
  const showBasketText = !currentRoute.match('/cart');

  return (
    <div data-test="header" className={`flex flex-col ${className}`}>
      <div className="py-14 px-8 space-y-8">
        {showCategoriesHeader && <CategoriesHeader />}
        <div className="text-7xl font-bold uppercase">{title}</div>
      </div>
      <div className="flex justify-between items-center bg-accessory px-8 py-6">
        <div className="flex">
          {showMenuAction && <SecondaryButton onClick={menuAction}>{menuText}</SecondaryButton>}
          {!!previousAction && (
            <IconButton disabled={!allowPreviousAction} onClick={previousAction}>
              {previousText}
            </IconButton>
          )}
        </div>
        <div className="flex items-center">
          {showBasketCount && (
            <div className="flex">
              {showBasketText && (
                <IconButton onClick={onBasketClick} iconReverse={true} Icon={ArrowRight}>
                  {basketText}
                </IconButton>
              )}
              <BasketCount count={count} onClick={onBasketClick} Icon={CartIconImage} />
            </div>
          )}
          {showSubtotal && <div className="font-bold text-4xl ml-8">{total}</div>}
          {isCouponEnabled && showCouponButton && (
            <SecondaryButton className="font-bold text-4xl ml-8" onClick={couponAction}>
              {couponText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
