import React from 'react';
import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
} from '@fingermarkglobal/cringer.components';

import { Decoration } from '../../components/stateless/decoration';
import { Header } from '../../components/stateless/header';
import { Footer } from '../../components/stateless/footer';

const CustomDefaultTemplate = ({
  children,
  timeoutEnabled = true,
  footerEnabled = true,
  headerEnabled = true,
  accessibilityActionEnabled = true,
  templateOverride = false,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    accessibilityEnabled,
    isTimeoutEnabled,
    timeoutActionOverride,
    isOnline,
    hideHeaderAndFooter,
    isCouponEnabled,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const showAccessibilityButton = accessibilityActionEnabled && !footerEnabled;

  const isAccessibilityButtonEnabled =
    accessibilityActionEnabled || showFooterAccessibilityButton || showAccessibilityButton;

  return (
    <div className="flex flex-col justify-between w-full h-screen">
      <Decoration showLogo={accessibilityEnabled && isAccessibilityButtonEnabled} />
      <AccessibilityWrapper
        className={`flex flex-col ${!accessibilityEnabled && 'accessibility-static'}`}
        isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && !hideHeaderAndFooter && (
            <Header {...header} isCouponEnabled={isCouponEnabled} />
          )}

          <div className="flex-grow overflow-auto">{children}</div>

          {footerEnabled && !hideHeaderAndFooter && (
            <Footer
              {...footer}
              accessibilityEnabled={accessibilityEnabled}
              showAccessibilityButton={showFooterAccessibilityButton}
            />
          )}

          {showAccessibilityButton && (
            <AccessibilityButton className="absolute bottom-0 left-0 z-40 block w-24 h-24 p-2 my-16 ml-8 shadow-lg" />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
